import { API } from "@project/shared"
import { IMerchant } from "../../interface"
import { InquiryInterface } from "../../interface/inquiry"

interface IInquiryResponse extends InquiryInterface {
  facility: IMerchant
}
export interface IReturnInquiry {
  data?: Array<IInquiryResponse>
  total_count?: number
}

export const fetchInquiries = async ({ queryKey }): Promise<IReturnInquiry> => {
  const payload = {}
  payload["page"] = queryKey[1]
  payload["limit"] = queryKey[2]
  if (queryKey[3] !== "" && queryKey[3]) {
    payload["keyword"] = queryKey[3]
  }
  if (queryKey[4] !== "" && queryKey[4]) {
    payload["inquiry_type"] = queryKey[4]
  }

  const res = await API.get<any>(`/inquiries`, {
    params: payload,
  })
  return res
}

export const getOneInquiry = async ({
  queryKey,
}): Promise<IInquiryResponse> => {
  const id = queryKey[1]
  const { data } = await API.get<IInquiryResponse>(`/inquiries/${id}`)
  return data
}

export const updateInquiry = async (data, id): Promise<any> => {
  return API.put(`/inquiries/${id}`, data)
}
