import { theme } from "@project/shared"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { fetchTransactionHistory } from "../../../services/resident"
import { StyledPaginationComponent, Table } from "../../atoms"
import { CsvDownload } from "../../molecules"
import { ConvertNumberToJPCurrency } from "@project/shared/src/utils/counter"
import dayjs from "dayjs"

interface ITransactionHistoryProps {
  resident_id?: string
  name?: string
}
const Container = styled.div`
  margin-top: 30px;
  .container-item {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon {
      cursor: pointer;
    }
    p {
      ${theme.typography.header}
    }
    .csv-wrapper {
      a {
        cursor: pointer;
        font-size: 16px;
        color: ${theme.font.colorPrimary};
        text-decoration: underline;
      }
    }
  }
`
const StylePrice = styled.span<{ color: string }>`
  text-align: right;
  color: ${({ color }) => color};
`
const TransactionHistory: React.FC<ITransactionHistoryProps> = ({
  resident_id,
  name,
}) => {
  const { t } = useTranslation()
  const [pages, setPages] = useState(1)
  const [pageCount, setPagecount] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const handlePageChange = (page) => {
    setPages(page)
  }

  const handlePageSizeChange = (_current, size) => {
    setPageSize(size)
  }

  const { isLoading, data: searchData } = useQuery(
    ["transaction-history", pages, pageSize, resident_id],
    fetchTransactionHistory,
    {
      refetchOnWindowFocus: false,
      select: (response) => ({
        data: response?.data?.map((data) => ({
          key: data.invoice_id,
          id: data.invoice_id,
          invoiceNumber: data.invoice_id || "-",
          business_type: data.facility?.name || "-",
          person_incharge: data.facility?.representative_staff?.name || "-",
          productName:
            data?.transaction_product_name || data?.description || "-",
          price: ConvertNumberToJPCurrency(data.amount) || "-",
          voucherBalance:
            ConvertNumberToJPCurrency(data.balance_after_transaction) || "-",
          created_at: dayjs(data?.created_at).format("YYYY/MM/DD HH:mm"),
          paymentType: t(data?.transaction_type),
        })),
        count: response?.total_count,
      }),
    }
  )
  useEffect(() => {
    setPagecount(searchData?.count || pageCount)
  })

  const columns = [
    {
      title: t("ID"),
      dataIndex: "id",
      key: "id",
      visible: false,
    },

    {
      title: t("Invoice number"),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
    },
    {
      title: t("Business type"),
      dataIndex: "business_type",
      key: "business_type",
    },
    {
      title: t("Person incharge name"),
      dataIndex: "person_incharge",
      key: "person_incharge",
    },
    {
      title: t("Product name"),
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: t("Payment Type"),
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: t("Price"),
      dataIndex: "price",
      key: "price",
    },
    {
      title: t("Voucher balance"),
      dataIndex: "voucherBalance",
      key: "voucherBalance",
    },
    {
      title: t("Transaction Created Date"),
      dataIndex: "created_at",
      key: "created_at",
    },
  ]
  const showData = columns.filter(
    (col) => col.dataIndex !== "id" && col.dataIndex !== "fullName"
  )
  return (
    <Container>
      <div className={"container-item"}>
        <p>{t("Transaction history list")}</p>
        <div className={"csv-wrapper"}>
          <CsvDownload
            enabled={!!resident_id}
            primaryApiUrlEndpoint={`/transactions/csv/resident/${resident_id}`}
            secondaryApiUrlEndpoint={"/check_csv_creation"}
            downloadFileName={t("residentTransactionHistoryCSVFileName", {
              username: name,
            })}
            attachTimeStamp={false}
          />
        </div>
      </div>
      <Table
        dataSource={searchData?.data}
        columns={showData}
        pagination={false}
        loading={isLoading}
      />
      <StyledPaginationComponent
        current={+pages}
        hideOnSinglePage={false}
        total={pageCount}
        showSizeChanger={false}
        pageSize={10}
        onChange={handlePageChange}
        onShowSizeChange={handlePageSizeChange}
      />
    </Container>
  )
}

export default TransactionHistory
