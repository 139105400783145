import React, { useContext } from "react"
import { userPool } from "@project/shared"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import Inquiry from "../../../public/assets/icons/inquiry.svg"
import UserManage from "../../../public/assets/icons/user_manage.svg"
import Home from "../../../public/assets/icons/home.svg"
import MerchantManage from "../../../public/assets/icons/merchant_manage.svg"
import AdminManage from "../../../public/assets/icons/admin_manage.svg"
import Logout from "../../../public/assets/icons/logout.svg"
import Event from "../../../public/assets/icons/event.svg"
import { AuthContext } from "../../../utils/AuthContext"
import { FileTextOutlined, GiftOutlined } from "@ant-design/icons"
import { SideBarComponent } from "@project/shared/src/components/organisms"
import { useQuery } from "react-query"
import { tenantDetail } from "../../../services"

interface SideBarProps {
  children?: any
}
const SideBar: React.FC<SideBarProps> = ({ children }) => {
  const { t } = useTranslation()
  const loggedInUser = userPool.getCurrentUser()
  const { setUser, user, setIsSuperAdmin, setIsAdmin } = useContext(AuthContext)
  const router = useRouter()

  const { data: tenant } = useQuery(
    ["tenantDetail", user?.["custom:tenantId"]],
    () => tenantDetail(Number(user?.["custom:tenantId"])),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      select: (data) => {
        return data?.["data"]
      },
      enabled: !!user?.["custom:tenantId"],
    }
  )

  const handleSignOut = async () => {
    setUser(null)
    setIsAdmin(false)
    setIsSuperAdmin(false)
    router.push("/login")
    loggedInUser.signOut()
  }
  let navItems = [
    {
      key: "home",
      icon: <Home />,
      label: t("Home"),
      onClick: () => router.push("/home"),
    },
    {
      key: "resident",
      icon: <UserManage />,
      label: t("Resident management"),
      onClick: () => router.push("/resident"),
    },
    {
      key: "transaction-list",
      icon: <FileTextOutlined />,
      label: t("Transaction List"),
      onClick: () => router.push("/transaction-list"),
    },
    {
      key: "subsidy",
      icon: <GiftOutlined />,
      label: "給付金",
      onClick: () => router.push("/subsidy"),
    },
    {
      key: "event-participation",
      icon: <Event />,
      label: t("event_participation"),
      onClick: () => router.push("/event-participation"),
    },
    {
      key: "merchant-management",
      icon: <MerchantManage />,
      label: t("Merchant management"),
      onClick: () => router.push("/merchant-management"),
    },
    {
      key: "admin-management",
      icon: <AdminManage />,
      label: t("Admin management"),
      onClick: () => router.push("/admin-management"),
    },
    {
      key: "inquiry",
      icon: <Inquiry />,
      label: t("Inquiry"),
      onClick: () => router.push("/inquiry"),
    },
    {
      key: "logout",
      icon: <Logout />,
      label: t("Logout"),
      onClick: handleSignOut,
    },
  ]
  // 給付金機能非表示
  if (tenant?.["name"] === "伊方町") {
    navItems = navItems.filter((item) => item.key !== "subsidy")
  }

  return (
    <SideBarComponent tenant={tenant} navItems={navItems} type={"admin"}>
      {children}
    </SideBarComponent>
  )
}
export { SideBar }
