import styled from "styled-components"
import { InputField } from "../../atoms"
import { useTranslation } from "react-i18next"
import { useFormik } from "formik"
import { theme, Button } from "@project/shared"
import { Spin } from "antd"
import { HtmlType, InquiryType } from "../../../constants"
import { UseMutateFunction } from "react-query"
import { IInquiryDetails } from "../../../interface"
import dayjs from "dayjs"

const Container = styled.div`
  .label {
    ${theme.typography.subLabel};
  }
  .error {
    ${theme.typography.subLabel};
    color: ${theme.font.colorAlert};
  }
  .field-wrapper {
    margin-bottom: 10px;
  }
  .button-container {
    display: flex;
    justify-content: end;
  }
  .data {
    margin-bottom: 30px;
    margin-top: 20px;
    font-size: 14px;
    margin-left: 13px;
    flex-wrap: wrap;
    overflow-wrap: break-word;
  }
`
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`
interface AdminProps {
  id?: string
  isLoading?: boolean
  onSubmit?: UseMutateFunction<IInquiryDetails, unknown, IInquiryDetails | void>
  data?: any
}

const InquiryDetails: React.FC<AdminProps> = ({
  isLoading,
  onSubmit,
  data,
}) => {
  const { t } = useTranslation()

  const initialValues: IInquiryDetails = data || {
    name: "",
    inquiry_type: "",
    description: "",
    created_at: "",
    memo: "",
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => onSubmit(values),
  })
  if (isLoading) {
    return (
      <LoaderWrapper>
        <Spin size={"default"} />
      </LoaderWrapper>
    )
  }

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <div className={"field-wrapper"}>
          <label className={"label"}>{t("Merchant Name")}</label>
          <p className={"data"}>{data?.name}</p>
        </div>
        <div className={"field-wrapper"}>
          <label className={"label"}>{t("Inquiry type")}</label>
          <p className={"data"}>{t(data?.inquiry_type)}</p>
        </div>
        <div className={"field-wrapper"}>
          <label className={"label"}>{t("Inquiry details")}</label>
          <p className={"data"}>{data?.description}</p>
        </div>
        <div className={"field-wrapper"}>
          <label className={"label"}>{t("Inquiry date & time")}</label>
          <p className={"data"}>
            {(data.created_at &&
              dayjs(data.created_at).format("YYYY/MM/DD HH:mm")) ||
              "-"}
          </p>
        </div>
        <div className={"field-wrapper"}>
          <InputField
            type={"textarea"}
            rows={4}
            label={t("Memo")}
            labelClassName={"label"}
            name={"memo"}
            {...formik.getFieldProps("memo")}
            errorClassName={"error"}
            value={formik.values.memo}
            onChange={(event: { target: { value: string } }) => {
              formik.setFieldValue("memo", event.target.value)
            }}
            maxLength={255}
            showCounting
            disabled={!formik.dirty}
          />
        </div>
        <div className={"button-container"}>
          <Button
            label={t("Save")}
            borderRadius={"10px"}
            height={"40px"}
            disabled={!formik.dirty}
            htmlType={HtmlType.SUBMIT}
          />
        </div>
      </form>
    </Container>
  )
}
export { InquiryDetails }
