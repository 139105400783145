import { API } from "@project/shared"
import {
  IEvent,
  IEventParticipation,
} from "../../interface/event-participation"

export const fetchEventParticipation = async ({
  queryKey,
}): Promise<IEventParticipation> => {
  const page = queryKey[1]
  const limit = queryKey[2]
  const searchQuery = queryKey[3]
  const start_date = queryKey[4]
  const end_date = queryKey[5]
  const params = {
    page: page,
    limit: limit,
    keyword: searchQuery,
    start_date: start_date,
    end_date: end_date,
  }
  const res = await API.get<any>(`/event_attendees`, {
    params,
  })
  return res
}

export const fetchEventParticipationByResidentID = async ({
  queryKey,
}): Promise<IEventParticipation> => {
  const page = queryKey[1]
  const limit = queryKey[2]
  const resident_id = queryKey[3]
  const params = {
    page: page,
    limit: limit,
    resident_id: resident_id,
  }
  const res = await API.get<any>(`/event_attendees`, {
    params,
  })
  return res
}
export const fetchEvent = async (): Promise<IEvent> => {
  const res = await API.get<any>(`/events`)
  return res
}
