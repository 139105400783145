import { API } from "@project/shared"
import { ResidentInterface } from "../../interface"
import { ITransactionHistory } from "../../interface/transaction-history"

export interface IReturnResident {
  total_count?: number
  data?: Array<ResidentInterface>
  count?: number
}

export const fetchResident = async ({ queryKey }): Promise<IReturnResident> => {
  const page = queryKey[1]
  const limit = queryKey[2]
  const searchQuery = queryKey[3]

  const res = await API.get<any>(`/residents`, {
    params: {
      page,
      limit,
      keyword: searchQuery,
    },
  })
  return res
}
export const fetchParticipation = async ({
  queryKey,
}): Promise<IReturnResident> => {
  const page = queryKey[1]
  const pageSize = queryKey[2]
  const resident = queryKey[3]
  const res = await API.get<any>(`/events/participations`, {
    params: {
      page,
      pageSize,
      resident_id: resident,
    },
  })
  return res
}
export const fetchTransactionHistory = async ({
  queryKey,
}): Promise<ITransactionHistory> => {
  const page = queryKey[1]
  const limit = queryKey[2]
  const id = queryKey[3]
  const res = await API.get<any>(`transactions/resident/${id}`, {
    params: {
      page,
      limit,
    },
  })
  return res
}

export const crateResident = (data) => {
  return API.post(`/resident`, data)
}

export const updateResident = async (data, id): Promise<any> => {
  return API.put(`/residents/${id}`, data)
}

export const deleteResident = async (id): Promise<any> => {
  return API.delete(`/residents/${id}`)
}

export const getOneResident = async ({
  queryKey,
}): Promise<ResidentInterface> => {
  const id = queryKey[1]
  const { data } = await API.get<ResidentInterface>(`/residents/${id}`)
  return data
}
