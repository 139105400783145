import styled from "styled-components"
import { Input } from "antd"
import { theme } from "@project/shared"
const { Search } = Input
interface SearchProps {
  value?: string
  loading?: boolean
  width?: number
  placeholder?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement> & (() => void)
  onSearch?: ((
    value: string,
    event?:
      | React.ChangeEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
  ) => void) &
    (() => void)
  className?: string
  size?: any
  allowClear?: boolean
}

const StyledSearch = styled(Search)`
  width: ${({ width }: SearchProps) => {
    return width ? `${width}px` : "100%"
  }};

  @media (max-width: 1347px) {
    width: 180px;
  }

  & .ant-input {
    border-radius: 2px;
    font-size: 12px;
    height: 40px;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
  .ant-input:focus,
  & .ant-input:hover {
    outline: none !important;
    box-shadow: none !important;
  }

  & .ant-btn-icon-only {
    background: ${theme.font.colorSecondary};
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border: 1px solid ${theme.border.colorSecondary4};
    padding: 9.5px;
    border-left: none;
    width: 40px !important;
    height: 42px;

    & .anticon {
      svg {
        fill: ${theme.svg.colorPrimary};
        height: 25px;
        width: 25px;
      }
    }
  }
  .ant-btn-icon-only:hover,
  & .ant-btn-icon-only:focus {
    outline: none !important;
    border: 1px solid ${theme.svg.colorPrimary} !important;
  }

  & .ant-input-affix-wrapper {
    padding: 0px 10px;
  }
  & .ant-input-group-addon {
    border-left: 1px solid ${theme.border.colorSecondary4} !important;
  }

  & .ant-input-group {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-input-suffix {
    display: none;
  }
  .ant-input-affix-wrapper:hover {
    .ant-input-suffix {
      display: flex !important;
    }
  }
`

export const SearchBar: React.FC<SearchProps> = ({
  value,
  onChange,
  onPressEnter,
  onSearch,
  loading,
  placeholder,
  className,
  size,
  allowClear = true,
  ...rest
}) => {
  return (
    <StyledSearch
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onPressEnter={onPressEnter}
      onSearch={onSearch}
      loading={loading}
      className={className}
      size={size ? size : "middle"}
      allowClear={allowClear}
      {...rest}
    />
  )
}
