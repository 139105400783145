import { theme } from "@project/shared"
import { Modal } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import ShowMoreText from "react-show-more-text"
import styled from "styled-components"
import { StyledPaginationComponent, Table } from "../../atoms"
import { CsvDownload } from "../../molecules"
import { fetchEventParticipationByResidentID } from "../../../services/event-participation"
interface IEventParticipationProps {
  id?: string
  name?: string
}
const LoadMore = styled.span`
  text-decoration: underline;
  color: ${theme.font.colorSecondary4};
  /* span {

  } */
`
const Container = styled.div`
  margin-top: 30px;
  .container-item {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon {
      cursor: pointer;
    }
    p {
      ${theme.typography.header}
    }
    .csv-wrapper {
      a {
        cursor: pointer;
        font-size: 16px;
        color: ${theme.font.colorPrimary};
        text-decoration: underline;
      }
    }
  }
`

const ParticipateRecord: React.FC<IEventParticipationProps> = ({
  id,
  name,
}) => {
  const { t } = useTranslation()
  const [pages, setPages] = useState(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [pageCount, setPagecount] = useState(0)
  const [modaldata, setmodaldata] = useState("")

  const showModal = (notes) => {
    setIsModalOpen(true)
    setmodaldata(notes)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handlePageChange = (page) => {
    setPages(page)
  }

  const handlePageSizeChange = (_current, size) => {
    setPageSize(size)
  }

  const {
    isLoading,
    isFetching,
    data: searchData,
  } = useQuery(
    ["participation-list", pages, pageSize, id],
    fetchEventParticipationByResidentID,
    {
      refetchOnWindowFocus: false,
      select: (response) => {
        return {
          data: response?.data?.map((data) => {
            return {
              key: data.id,
              id: data.event_id,
              eventName: data.event.name || "-",
              eventNotes: data.memo || "-",
              registeredDate:
                (data.created_at &&
                  moment(data.created_at).format("YYYY/MM/DD")) ||
                "-",
            }
          }),
          count: response?.total_count,
        }
      },
    }
  )
  useEffect(() => {
    setPagecount(searchData?.count || pageCount)
  })

  const columns = [
    {
      title: t("ID"),
      dataIndex: "id",
      key: "id",
      visible: false,
    },
    {
      title: t("Event name"),
      dataIndex: "eventName",
      key: "eventName",
      width: 200,
    },
    {
      title: t("event_notes"),
      dataIndex: "eventNotes",
      ellipsis: true,
      render: (eventNotes: string) => (
        <>
          <ShowMoreText
            lines={3}
            more={
              <LoadMore onClick={() => showModal(eventNotes)}>
                {t("show_more")}
              </LoadMore>
            }
            expandByClick={false}
          >
            {eventNotes}
          </ShowMoreText>
        </>
      ),
      key: "eventNotes",
    },
    {
      title: t("registered_date"),
      dataIndex: "registeredDate",
      key: "registeredDate",
      width: 200,
    },
  ]
  const showData = columns.filter(
    (col) => col.dataIndex !== "id" && col.dataIndex !== "fullName"
  )

  return (
    <Container>
      <div className={"container-item"}>
        <p>{t("Event participation record")}</p>
        <div className={"csv-wrapper"}>
          <CsvDownload
            primaryApiUrlEndpoint={"event_attendees/csv"}
            secondaryApiUrlEndpoint={"/check_csv_creation"}
            queryParameters={{ resident_id: id }}
            downloadFileName={t("residentEventParticipationRecordCSVFileName", {
              username: name,
            })}
            attachTimeStamp={false}
          />
        </div>
      </div>
      <Modal
        title={t("event_notes")}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>{modaldata}</p>
      </Modal>
      <Table
        dataSource={searchData?.data}
        columns={showData}
        pagination={false}
        loading={isLoading || isFetching}
      />
      <StyledPaginationComponent
        current={+pages}
        hideOnSinglePage={false}
        total={pageCount}
        showSizeChanger={false}
        pageSize={pageSize}
        onChange={handlePageChange}
        onShowSizeChange={handlePageSizeChange}
      />
    </Container>
  )
}

export default ParticipateRecord
