import { API } from "@project/shared"
import moment from "moment"
import TransactionHistory from "../../components/organisms/ResidentForm/transaction-history"
import { ITransactionHistory } from "../../interface/transaction-history"

export interface IReturnTransaction {
  data?: Array<ITransactionHistory>
  total_count?: number
  timestamp?: string
  statusCode?: number
  message?: string
}

interface IDashboardValues {
  todays_registrant_number: string
  todays_topup_amount: string
  todays_trading_amount: string
}

export const fetchTransaction = async (): Promise<IReturnTransaction> => {
  const start_date = moment().format("YYYY/MM/DD")

  const end_date = moment().add(1, "day").format("YYYY/MM/DD")
  const { data } = await API.get<any>("/transactions/summary", {
    params: {
      start_date,
      end_date,
    },
  })
  return data as IReturnTransaction
}

export const fetchTransactionList = async ({
  queryKey,
}): Promise<ITransactionHistory> => {
  const page = queryKey[1]
  const limit = queryKey[2]
  const searchQuery = queryKey[3]
  const facility_id = queryKey[4]

  const res = await API.get<any>("/transactions", {
    params: { page, limit, keyword: searchQuery, facility_id },
  })
  return res
}

export const fetchDashboardValues = (): Promise<IDashboardValues> => {
  return API.get("/dashboard")
}
