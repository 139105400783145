import React from "react"
import { Pagination } from "antd"
import styled from "styled-components"
import { theme } from "../../../../shared/src/theme"
import { t } from "i18next"
interface iPagination {
  className?: string
  current?: number
  hideOnSinglePage?: boolean
  total?: number
  showSizeChanger?: boolean
  pageSize?: number
  onChange?: (page: number, pageSize: number) => void
  onShowSizeChange?: (current: number, size: number) => void
}
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80px;
`
const StyledPagination = styled(Pagination)`
  .ant-pagination-item {
    color: ${theme.font.colorSecondary2};
    border: 1px solid ${theme.border.colorSecondary6} !important;
    border-radius: 4px;
  }
  & .ant-pagination-item-active {
    border-color: ${theme.border.colorSecondary5} !important;
  }
  & .ant-pagination-item-active,
  li:hover {
    & a {
      color: ${theme.border.colorSecondary5};
    }
    border-color: ${theme.border.colorSecondary5};
  }
  .ant-pagination-next,
  .ant-pagination-prev {
    &:hover {
      button {
        color: ${theme.border.colorSecondary5};
        border: 1px solid ${theme.border.colorSecondary5};
      }
    }
    button:disabled {
      border: 1px solid ${theme.border.colorSecondary6} !important;
      border-radius: 4px;
      color: ${theme.font.colorSecondary3} !important;
    }
  }
`

export const StyledPaginationComponent: React.FC<iPagination> = ({
  current,
  hideOnSinglePage = true,
  showSizeChanger = false,
  pageSize = 10,
  onChange,
  onShowSizeChange,
  total,
  className,
}) => {
  return (
    <Wrapper className={className}>
      <StyledPagination
        current={current}
        hideOnSinglePage={hideOnSinglePage}
        total={total}
        showSizeChanger={showSizeChanger}
        pageSize={pageSize}
        onChange={onChange}
        onShowSizeChange={onShowSizeChange}
        showTotal={(total, range) =>
          `${t("paginationKey", {
            range: `${range[0]}-${range[1]}`,
            total: `${total}`,
          })}`
        }
      />
    </Wrapper>
  )
}
