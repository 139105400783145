import React from "react"
import { Button } from "antd"
import styled from "styled-components"
import { theme } from "@project/shared"
import { HtmlType } from "../../../constants"
interface ButtonProps {
  className?: string
  label?: string
  borderWidth?: string
  borderColor?: string
  borderRadius?: string
  backgroundColor?: string
  color?: string
  colorHover?: string
  borderColorHover?: string
  backgroundColorHover?: string
  height?: string
  width?: string
  minWidth?: string
  margin?: string
  bold?: boolean
  htmlType?: HtmlType
  loading?: boolean
  onClick?: any
}

const heightDefault = "60px"
const minWidthDefault = "120px"

const StyledButton = styled.div<ButtonProps>`
  .button {
    border: ${({ borderWidth, borderColor }: ButtonProps) => {
      return borderWidth && borderColor
        ? `${borderWidth} solid ${borderColor}`
        : "none"
    }};
    padding: 0px 15px;
    border-radius: ${({ borderRadius }: ButtonProps) => {
      return borderRadius ? borderRadius : theme.border.radiusPrimary
    }};
    background-color: ${({ backgroundColor }: ButtonProps) => {
      return backgroundColor ? backgroundColor : theme.background.colorPrimary
    }};
    color: ${({ color }: ButtonProps) => {
      return color ? color : theme.font.colorSecondary
    }};
    height: ${({ height }: ButtonProps) => {
      return height ? height : heightDefault
    }};
    width: ${({ width }: ButtonProps) => {
      return width ? width : `auto`
    }};
    min-width: ${({ minWidth }: ButtonProps) => {
      return minWidth ? minWidth : minWidthDefault
    }};
    margin: ${({ margin }: ButtonProps) => {
      return margin && margin
    }};
    font-weight: ${({ bold }: ButtonProps) => {
      return bold && "bold"
    }};
    :hover {
      border-color: ${({ borderColorHover }: ButtonProps) => {
        return borderColorHover ? borderColorHover : "transparent"
      }};
      background-color: ${({ backgroundColorHover }: ButtonProps) => {
        return backgroundColorHover
          ? backgroundColorHover
          : theme.background.colorPrimary
      }};
      color: ${({ colorHover }: ButtonProps) => {
        return colorHover ? colorHover : theme.font.colorSecondary
      }};
    }
  }
`

const ButtonComponent: React.FC<ButtonProps> = ({
  label,
  htmlType,
  loading,
  onClick,
  ...props
}) => {
  return (
    <StyledButton {...props}>
      <Button
        className={"button"}
        onClick={onClick}
        htmlType={htmlType}
        loading={loading}
      >
        {label}
      </Button>
    </StyledButton>
  )
}

export { ButtonComponent }
