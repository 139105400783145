import { API } from "@project/shared"
import { IAdminManagement } from "../../interface/admin-management"
export interface IReturnAdmin {
  data?: Array<IAdminManagement>
  total_count?: number
}

export const fetchAdmins = async ({ queryKey }): Promise<IReturnAdmin> => {
  const page = queryKey[1]
  const pageSize = queryKey[2]
  const searchQuery = queryKey[3]

  const res = await API.get<any>("/admin_users", {
    params: {
      page,
      limit: pageSize,
      keyword: searchQuery,
    },
  })
  return res
}

export const createAdmin = async (data): Promise<any> => {
  const requestData = { ...data, phone_number: data.phone_number?.toString() }
  return API.post(`/admin_users`, requestData)
}
export const getOneAdmin = async ({ queryKey }): Promise<IAdminManagement> => {
  const id = queryKey[1]
  const { data } = await API.get<IAdminManagement>(`/admin_users/${id}`)
  return data
}

export const updateAdmin = async (data, id): Promise<any> => {
  return API.put(`/admin_users/${id}`, data)
}
export const updateAdminPassword = async (data: {
  password: string
  id: string
}) => {
  return API.post("/admin_users/change_password", data)
}
