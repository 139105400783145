import { Button, InputField, theme } from "@project/shared"
import { Spin } from "antd"
import { FocusError } from "focus-formik-error"
import { useFormik } from "formik"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { UseMutateFunction } from "react-query"
import styled from "styled-components"
import * as yup from "yup"
import { HtmlType, UserStatus } from "../../../constants"
import { IMerchant } from "../../../interface"
import { ErrorLabel, RadioButton } from "../../atoms"

const Container = styled.div`
  .label {
    ${theme.typography.subLabel};
  }
  .error {
    ${theme.typography.subLabel};
    color: ${theme.font.colorAlert};
  }
  .field-wrapper {
    margin-bottom: 10px;
  }
  .button-container {
    display: flex;
    justify-content: end;
  }
`
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`

interface MerchantProps {
  id?: string
  isLoading?: boolean
  onSubmit?: UseMutateFunction<IMerchantDetail, unknown, any>
  data?: IMerchant
  errors?: ErrorProps[] | null
}

interface IMerchantDetail {
  name: string
  address: string
  representative_phone_number: string
  representative_email: string
  representative_name: string
  representative_password: string
  status: string
}
interface ErrorProps {
  Field: string
  Message: string
}
const MerchantForm: React.FC<MerchantProps> = ({
  isLoading,
  onSubmit,
  data,
  id,
  errors,
}) => {
  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    name: yup.string().required(
      `${t("inputValidationMessage", {
        fieldName: `${t("Business name")}`,
      })}`
    ),
    representative_email: yup
      .string()
      .email(t("Email address format is incorrect."))
      .required(
        `${t("inputValidationMessage", {
          fieldName: `${t("Email")}`,
        })}`
      ),
    representative_name: yup.string().required(
      `${t("inputValidationMessage", {
        fieldName: `${t("Person incharge name")}`,
      })}`
    ),
    representative_password: id
      ? yup
          .string()
          .matches(
            /^(?=.*[A-Z])(?=.*\d).{6,20}$/,
            t(
              "Password must be the combination of uppercase, lowercase & numbers with at least 6 and below 20 characters"
            )
          )
      : yup
          .string()
          .matches(
            /^(?=.*[A-Z])(?=.*\d).{6,20}$/,
            t(
              "Password must be the combination of uppercase, lowercase & numbers with at least 6 and below 20 characters"
            )
          )
          .required(
            `${t("inputValidationMessage", {
              fieldName: `${t("Password")}`,
            })}`
          ),

    address: yup.string().required(
      `${t("inputValidationMessage", {
        fieldName: `${t("Address")}`,
      })}`
    ),
    status: yup.string().required(
      `${t("inputValidationMessage", {
        fieldName: `${t("Status")}`,
      })}`
    ),
    representative_phone_number: yup
      .string()
      .matches(
        /^\d{2,4}-?\d{2,4}-?\d{2,4}-?\d+$/,
        t("Phone number can only contain numbers (0-9) and hyphen (-)")
      )
      .test("length", t("Phone number is incorrect"), (val) => {
        if (!val) return true
        const stripped = val.replace(/-/g, "")
        return stripped.length >= 10 && stripped.length <= 12
      })
      .required(
        `${t("inputValidationMessage", {
          fieldName: `${t("Phone")}`,
        })}`
      ),
  })

  const initialValues: IMerchantDetail = {
    name: data?.name || "",
    address: data?.address || "",
    representative_phone_number: data?.representative_staff?.phone_number || "",
    representative_email: data?.representative_staff?.email || "",
    representative_name: data?.representative_staff?.name || "",
    representative_password: "",
    status: data?.status || UserStatus.USING,
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!values?.representative_password)
        delete values.representative_password
      onSubmit({
        ...values,
        representative_phone_number:
          values.representative_phone_number.toString(),
      })
    },
  })

  const options = [
    { label: t("using"), value: UserStatus.USING },
    { label: t("suspended"), value: UserStatus.SUSPENDED },
  ]
  useEffect(() => {
    if (errors) {
      const error = errors.reduce((acc, item) => {
        const key = `error_${item.Field}`
        if (!acc[item.Field]) {
          acc[item.Field] = t(key) ?? item.Message
        }
        return acc
      }, {})
      formik.setErrors(error)
    }
  }, [errors])

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Spin size={"default"} />
      </LoaderWrapper>
    )
  }

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <FocusError formik={formik} />
        <div className={"field-wrapper"}>
          <InputField
            label={t("Business name")}
            error={formik.touched.name && formik.errors.name}
            errorClassName={"error"}
            name={"businessName"}
            onBlur={formik.handleBlur}
            {...formik.getFieldProps("name")}
            onChange={(e) => {
              formik.setFieldValue("name", e.target.value)
            }}
            height={"42px"}
            required
            labelClassName={"label"}
            maxLength={100}
            showCounting
          />
        </div>
        <div className={"field-wrapper"}>
          <InputField
            label={t("Person incharge name")}
            name={"personInchargeName"}
            error={
              formik.touched.representative_name &&
              formik.errors.representative_name
            }
            errorClassName={"error"}
            height={"42px"}
            {...formik.getFieldProps("representative_name")}
            onChange={(e: { target: { value: any } }) => {
              const value = e.target.value
              const isValid = !/[0-9_]/.test(value)
              if (isValid) {
                formik.setFieldValue("representative_name", value)
              }
            }}
            onBlur={formik.handleBlur}
            required
            labelClassName={"label"}
            maxLength={50}
            showCounting
          />
        </div>
        <div className={"field-wrapper"}>
          <InputField
            label={t("Phone number")}
            name={"phone"}
            type={"text"}
            errorClassName={"error"}
            error={
              formik.touched.representative_phone_number &&
              formik.errors.representative_phone_number
            }
            height={"42px"}
            value={formik.values.representative_phone_number}
            onChange={(e) => {
              const pattern = /^[0-9][0-9-]*$/
              const hyphens = (e.target.value.match(/-/g) || []).length
              const digits = e.target.value.length - hyphens <= 12
              if (
                (pattern.test(e.target.value) || e.target.value == "") &&
                hyphens <= 3 &&
                digits
              ) {
                formik.setFieldValue(
                  "representative_phone_number",
                  e.target.value
                )
              }
            }}
            onBlur={formik.handleBlur}
            labelClassName={"label"}
            maxLength={15}
            showCounting
            required
            placeholder={"XXXX-XXXX-XXXX"}
          />
        </div>
        <div className={"field-wrapper"}>
          <InputField
            label={t("Address")}
            errorClassName={"error"}
            height={"42px"}
            {...formik.getFieldProps("address")}
            error={formik.touched.address && formik.errors.address}
            required
            labelClassName={"label"}
            maxLength={100}
            showCounting
          />
        </div>
        <div className={"field-wrapper"}>
          <InputField
            name={"Email"}
            label={t("Email")}
            value={formik.values.representative_email}
            errorClassName={"error"}
            height={"42px"}
            {...formik.getFieldProps("representative_email")}
            error={
              formik.touched.representative_email &&
              formik.errors.representative_email
            }
            required
            labelClassName={"label"}
          />
        </div>
        <div className={"field-wrapper"}>
          {!id && (
            <>
              <InputField
                type={"password"}
                errorClassName={"error"}
                height={"42px"}
                label={t("Password")}
                {...formik.getFieldProps("representative_password")}
                required={id ? false : true}
                labelClassName={"label"}
                subLabel={
                  id ? t("Enter only if you want to change your password") : ""
                }
                maxLength={20}
                minLength={6}
              />
              <ErrorLabel>
                <span
                  className={`warning ${
                    formik.errors.representative_password &&
                    formik.touched.representative_password &&
                    "validation-error"
                  }`}
                >
                  {t(
                    "Password must be the combination of uppercase, lowercase & numbers with at least 6 and below 20 characters"
                  )}
                </span>
              </ErrorLabel>
            </>
          )}
        </div>
        <div className={"field-wrapper"}>
          <RadioButton
            options={options}
            onChange={formik.handleChange}
            value={formik.values.status}
            {...formik.getFieldProps("status")}
            error={formik.touched.status && formik.errors.status}
            label={t("Status")}
            required
          />
        </div>

        <div className={"button-container"}>
          <Button
            label={t(id ? "Update" : "Register")}
            borderRadius={"10px"}
            height={"40px"}
            htmlType={HtmlType.SUBMIT}
            disabled={!formik.dirty}
          />
        </div>
      </form>
    </Container>
  )
}
export { MerchantForm }
