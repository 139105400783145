import { Radio, RadioChangeEvent } from "antd"
import React from "react"
import styled from "styled-components"
import { theme } from "@project/shared"
import { Size } from "../../../constants"
export interface RadioOptions {
  label: string
  value: string | boolean | number
}

export interface RadioProps {
  disabled?: boolean
  name?: string
  onChange?: (e: RadioChangeEvent) => void
  className?: string
  options: RadioOptions[]
  value?: string | number | boolean
  label?: string
  error?: string
  required?: boolean
  size?: Size
}

const StyledRadioButton = styled(Radio)`
  @media (max-width: 800px) {
    width: 120px;
  }
  & .ant-radio-inner {
    background-color: ${theme.font.colorSecondary};
    border: solid 2px ${theme.border.colorSecondary2};
    opacity: 1;
  }
  & .ant-radio-checked .ant-radio-inner {
    background-color: ${theme.background.colorSecondary1};
    border-color: ${theme.border.blueColor};
    opacity: 1;
  }

  & .ant-radio-checked .ant-radio-inner::after {
    background-color: ${theme.border.blueColor};
    opacity: 1;
  }
`
const ErrorText = styled.p`
  color: ${theme.font.colorAlert};
`

const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 2px;
`

const Label = styled.div`
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
`

const RequiredLabel = styled.div`
  line-height: 20px;
  color: ${theme.font.colorAlert};
  display: flex;
  justify-content: left;
`
const RadioButton: React.FC<RadioProps> = ({
  name,
  onChange,
  options,
  className,
  label,
  required,
  value,
  size,
  error,
  ...rest
}: RadioProps) => {
  const radioButton = []
  for (let id = 0; id < options.length; id++) {
    radioButton.push(
      <StyledRadioButton {...rest} key={id} value={options[id].value}>
        {options[id].label}
      </StyledRadioButton>
    )
  }

  return (
    <div className={className}>
      {label && (
        <LabelContainer>
          <Label>{label}</Label>
          {required && <RequiredLabel>{"*"}</RequiredLabel>}
        </LabelContainer>
      )}
      <Radio.Group name={name} size={size} onChange={onChange} value={value}>
        {radioButton}
      </Radio.Group>
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  )
}

export { RadioButton }
