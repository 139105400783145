import { getBalanceWithExpiryDate, theme, Select } from "@project/shared"
import { Modal, Spin, Image, Input } from "antd"
import { FocusError } from "focus-formik-error"
import { useFormik } from "formik"
import moment from "moment"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { UseMutateFunction, useQuery } from "react-query"
import styled from "styled-components"
import * as yup from "yup"
import { calculate_age, HtmlType } from "../../../constants"
import { ResidentInterface } from "../../../interface"
import EditOutlined from "../../../public/assets/icons/edit1.svg"
import { Button, DatePicker, InputField } from "../../atoms"
import ParticipateRecord from "./participate-record"
import TransactionHistory from "./transaction-history"
import { tenantDetail } from "../../../services"
import { AuthContext } from "../../../utils"

const Loader = styled.div`
  justify-content: center;
  display: flex;
`
const Container = styled.div`
  .content-item {
    border-bottom: solid 1px ${theme.border.colorSecondary};
    display: grid;
    grid-template-columns: 2fr 4fr 1fr;
    align-items: center;
    padding: 5px;
  }
  img {
    border-radius: 50%;
    object-fit: cover;
  }
  .ant-image-mask {
    border-radius: 50%;
  }
  .error {
    ${theme.typography.subLabel};
    color: ${theme.font.colorAlert};
  }
  .field-wrapper {
    margin-bottom: 10px;
    .error {
      color: ${theme.font.colorAlert};
      text-align: center;
    }
  }
  .button-container {
    display: flex;
    justify-content: end;
  }
  .container {
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }
  .content {
    width: 100%;
  }
  .delete-resident-button-container {
    height: 100px;
    display: flex;
    justify-content: end;
  }
  .label {
    margin-top: 10px;
  }
  .input {
    border: none;
    box-shadow: none;
  }
  .enabled {
    border: none;
    box-shadow: none;
    background-color: ${theme.background.colorSecondary};
  }
  .iconClass {
    justify-content: end;
    cursor: pointer;
    gap: 10px;
  }
`

const StyledInputField = styled(InputField)`
  border-bottom: solid 1px ${theme.border.colorSecondary};
  display: grid;
  grid-template-columns: ${(props) =>
    props.fullwidth ? "2fr 5fr" : "2fr 4fr 1fr"};
  align-items: center;
  padding: 5px;
`
const StyledSelectField = styled(Select)`
  .input .ant-select-selector {
    height: 42px;
    border-radius: 4px;
    border: none;
    background: ${theme.background.colorSecondary};
    justify-content: center;
    align-items: center;
  }
  .iconClass {
    margin-left: 54px;
    display: flex;
  }
`

interface ResidentProps {
  id?: string
  isLoading?: boolean
  isDeleting?: boolean
  onSubmit?: UseMutateFunction<
    ResidentInterface,
    unknown,
    ResidentInterface | void
  >
  onDelete?: (id: string) => void
  data?: ResidentInterface
}

const ResidentForm: React.FC<ResidentProps> = ({
  isLoading,
  isDeleting,
  onSubmit,
  onDelete,
  data,
  id,
}) => {
  const { user } = useContext(AuthContext)
  const { data: tenant } = useQuery(
    ["tenantDetail", user?.["custom:tenantId"]],
    () => tenantDetail(Number(user?.["custom:tenantId"])),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      select: (data) => {
        return data?.["data"]
      },
      enabled: !!user?.["custom:tenantId"],
    }
  )
  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .max(100, t("Limit exceed"))
      .required(
        `${t("apiInputFieldError", {
          fieldName: `${t("Full name")}`,
        })}`
      ),
    furigana: yup
      .string()
      .max(100, t("Limit exceed"))
      .required(
        `${t("apiInputFieldError", {
          fieldName: "フリガナ",
        })}`
      ),
    address: yup
      .string()
      .max(100, t("Limit exceed"))
      .required(
        `${t("apiInputFieldError", {
          fieldName: `${t("Address")}`,
        })}`
      ),
    phone_number: yup
      .string()
      .matches(
        /^\d{2,4}-?\d{2,4}-?\d{2,4}-?\d+$/,
        t("Phone number can only contain numbers (0-9) and hyphen (-)")
      )
      .test("length", t("Phone number is incorrect"), (val) => {
        if (!val) return true
        const stripped = val.replace(/-/g, "")
        return stripped.length >= 10 && stripped.length <= 12
      }),
    village: yup
      .string()
      .max(20, t("Limit exceed"))
      .required(
        `${t("apiInputFieldError", {
          fieldName: `${t("Village name")}`,
        })}`
      ),
  })
  const [disable, setDisable] = useState({
    id: id ?? true,
    name: id ?? true,
    furigana: id ?? true,
    gender: id ?? true,
    birth_date: id ?? true,
    age: id ?? true,
    phone: id ?? true,
    address: id ?? true,
    villageName: id ?? true,
    registeredBy: id ?? true,
  })

  const onClick = (name) => {
    setDisable({ ...disable, [name]: false })
  }

  const initialValues: ResidentInterface = data || {
    name: "",
    gender: "",
    birth_date: "",
    phone_number: "",
    address: "",
    village: "",
    // event_id: "",
    facility: {},
  }

  const formik = useFormik({
    validationSchema,
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit(values)
    },
  })
  const options = [
    { label: t("Male"), value: "Male" },
    { label: t("Female"), value: "Female" },
    { label: t("others"), value: "others" },
  ]
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] =
    useState(false)
  const [deleteResidentName, setDeleteResidentName] = useState(null)

  const handleDeleteResident = async () => {
    if (onDelete) {
      onDelete(formik.values.id)
    }
  }

  if (isLoading || isDeleting) {
    return (
      <Loader>
        <Spin size={"default"} />
      </Loader>
    )
  }
  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <FocusError formik={formik} />
        <div className={"container"}>
          <div className={"content"}>
            <Image
              width={100}
              height={100}
              src={data?.presigned_url}
              preview={{
                mask: t("Preview"),
              }}
            />
            <div className={"field-wrapper"}>
              <StyledInputField
                label={t("ID")}
                height={"42px"}
                fullwidth={"true"}
                inputClassName={"input"}
                labelClassName={"label"}
                value={formik.values.id}
                placeholder={t("Please enter")}
                disabled={disable.id && true}
                iconClassName={"iconClass"}
              />
            </div>
            <div className={"field-wrapper"}>
              <StyledInputField
                label={t("Full name")}
                errorClassName={"error"}
                {...formik.getFieldProps("name")}
                height={"42px"}
                inputClassName={disable ? "enabled" : "input"}
                labelClassName={"label"}
                maxLength={45}
                placeholder={t("Please enter")}
                icon={<EditOutlined onClick={() => onClick("name")} />}
                disabled={disable.name && true}
                iconClassName={"iconClass"}
                showCounting
              />
              <p className={"error"}>{formik.errors.name}</p>
            </div>
            <div className={"field-wrapper"}>
              <StyledInputField
                label={"フリガナ"}
                errorClassName={"error"}
                {...formik.getFieldProps("furigana")}
                height={"42px"}
                inputClassName={disable ? "enabled" : "input"}
                labelClassName={"label"}
                maxLength={45}
                placeholder={t("Please enter")}
                icon={<EditOutlined onClick={() => onClick("furigana")} />}
                disabled={disable.furigana && true}
                iconClassName={"iconClass"}
                showCounting
              />
              <p className={"error"}>{formik.errors.furigana}</p>
            </div>
            <div className={"field-wrapper"}>
              <StyledSelectField
                hideSuffixIcon={true}
                options={options}
                bordered={!disable.gender && true}
                label={t("Gender")}
                placeholder={t("Please select")}
                disabled={disable.gender && true}
                className={"content-item"}
                inputClassName={"input"}
                {...formik.getFieldProps("gender")}
                iconClassName={"iconClass"}
                onChange={(a) => {
                  formik.setFieldValue("gender", a)
                }}
                icon={<EditOutlined onClick={() => onClick("gender")} />}
              />
              <p className={"error"}>{formik.errors.gender}</p>
            </div>

            <div className={"field-wrapper"}>
              <DatePicker
                hideSuffixIcon={disable.birth_date && true}
                label={t("Date of birth")}
                borderRadius={"10px"}
                allowClear={false}
                format={"YYYY/MM/DD"}
                value={moment(formik.values.birth_date)}
                onChange={(value) => {
                  formik.setFieldValue(
                    "birth_date",
                    moment(value).format("YYYY-MM-DD")
                  )
                }}
                className={"content-item"}
                inputClassName={disable ? "enabled" : "input"}
                placeholder={t("Please enter")}
                icon={<EditOutlined onClick={() => onClick("birth_date")} />}
                disabled={disable.birth_date && true}
                iconClassName={"iconClass"}
              />
            </div>
            <div className={"field-wrapper"}>
              <StyledInputField
                label={t("Age")}
                placeholder={t("Please enter")}
                errorClassName={"error"}
                height={"42px"}
                value={calculate_age(formik.values.birth_date)}
                disabled={disable.age && true}
                inputClassName={"input"}
              />
            </div>
          </div>
          <div className={"content"}>
            <div className="delete-resident-button-container">
              {/* 利用中止機能表示分け */}
              {tenant?.["name"] !== "伊方町" ? (
                <Button
                  label="利用中止"
                  color="red"
                  colorHover="white"
                  backgroundColorHover="red"
                  backgroundColor="rgba(0, 0, 0, 0)"
                  borderColor="red"
                  borderWidth="2px"
                  height="40px"
                  onClick={() => setIsOpenDeleteConfirmModal(true)}
                />
              ) : null}
            </div>
            <div className={"field-wrapper"}>
              <StyledInputField
                label={t("Phone")}
                name={"phone_number"}
                type={"text"}
                errorClassName={"error"}
                height={"42px"}
                value={formik.values.phone_number}
                onChange={(e) => {
                  const pattern = /^[0-9][0-9-]*$/
                  const hyphens = (e.target.value.match(/-/g) || []).length
                  const digits = e.target.value.length - hyphens <= 12
                  if (
                    (pattern.test(e.target.value) || e.target.value == "") &&
                    hyphens <= 3 &&
                    digits
                  ) {
                    formik.setFieldValue("phone_number", e.target.value)
                  }
                }}
                onBlur={formik.handleBlur}
                maxLength={15}
                showCounting
                inputClassName={disable ? "enabled" : "input"}
                icon={<EditOutlined onClick={() => onClick("phone")} />}
                disabled={disable.phone && true}
                iconClassName={"iconClass"}
                placeholder={"XXXX-XXXX-XXXX"}
              />
              <p className={"error"}>{formik.errors.phone_number}</p>
            </div>
            <div className={"field-wrapper"}>
              <StyledInputField
                label={t("Address")}
                errorClassName={"error"}
                height={"42px"}
                {...formik.getFieldProps("address")}
                maxLength={100}
                showCounting
                inputClassName={disable ? "enabled" : "input"}
                placeholder={t("Please enter")}
                icon={<EditOutlined onClick={() => onClick("address")} />}
                disabled={disable.address && true}
                iconClassName={"iconClass"}
              />
              <p className={"error"}>{formik.errors.address}</p>
            </div>
            <div className={"field-wrapper"}>
              <StyledInputField
                label={t("Village name")}
                errorClassName={"error"}
                height={"42px"}
                {...formik.getFieldProps("village")}
                maxLength={20}
                showCounting
                inputClassName={disable ? "enabled" : "input"}
                placeholder={t("Please enter")}
                icon={<EditOutlined onClick={() => onClick("villageName")} />}
                disabled={disable.villageName && true}
                iconClassName={"iconClass"}
              />
              <p className={"error"}>{formik.errors.village}</p>
            </div>
            <div className={"field-wrapper"}>
              <StyledInputField
                label={t("Voucher balance")}
                errorClassName={"error"}
                height={"42px"}
                value={`¥${Number(formik.values.balance)?.toLocaleString()}`}
                inputClassName={"input"}
                placeholder={t("Please enter")}
                disabled={true}
              />
            </div>

            <div className={"field-wrapper"}>
              <StyledInputField
                label={t("Used balance")}
                errorClassName={"error"}
                height={"42px"}
                value={`¥${Number(
                  formik.values.used_voucher
                )?.toLocaleString()}`}
                inputClassName={"input"}
                placeholder={t("Please enter")}
                disabled={true}
              />
            </div>
            <div className={"field-wrapper"}>
              <StyledInputField
                label={t("Balance with Expiry Date")}
                errorClassName={"error"}
                height={"42px"}
                value={`¥${getBalanceWithExpiryDate(
                  data?.individual_balances
                )?.toLocaleString()}`}
                inputClassName={"input"}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className={"button-container"}>
          <Button
            label={t("Save")}
            borderRadius={"10px"}
            height={"40px"}
            htmlType={HtmlType.SUBMIT}
          />
        </div>
      </form>
      <TransactionHistory resident_id={id} name={data?.name} />
      <ParticipateRecord id={id} name={data?.name} />
      <Modal
        title={"確認"}
        open={isOpenDeleteConfirmModal}
        onOk={handleDeleteResident}
        onCancel={() => {
          setIsOpenDeleteConfirmModal(false)
          setDeleteResidentName(null)
        }}
        cancelText={"キャンセル"}
        okText={"利用中止"}
        okButtonProps={{
          style: { backgroundColor: "#d9363e", borderWidth: 0 },
          disabled: deleteResidentName !== formik.values.name,
        }}
      >
        <p>{"こちらの町民の利用を中止しますか？"}</p>
        <p>
          {"利用中止するためには「"}
          {formik.values.name}
          {"」と入力してください。"}
        </p>
        <Input
          placeholder={formik.values.name}
          value={deleteResidentName}
          onChange={(e) => {
            const inputValue = e.target.value
            setDeleteResidentName(inputValue)
            formik.setFieldValue("deleteResidentName", inputValue)
          }}
        />
        <p>{"(!)利用中止後に利用再開はできません"}</p>
      </Modal>
    </Container>
  )
}
export { ResidentForm }
