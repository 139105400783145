import { i18next } from "@project/shared"
import { ConfigProvider, DatePicker } from "antd"
import jaJP from "antd/lib/locale/ja_JP"
import moment from "moment"
import "moment/locale/ja"
import React from "react"
import styled from "styled-components"
moment.locale("ja")

const { RangePicker } = DatePicker

interface IRangePickerComponentProps {
  height?: string
  width?: string
  borderRadius?: string
  disabledDate?: (moment) => boolean
  value?: [moment.Moment, moment.Moment]
  onChange?: (moment, string) => void
  picker?: "time" | "date" | "week" | "month" | "quarter" | "year"
  allowClear?: boolean
  format?: string
  placeholder?: [string, string]
}
const StyledRangePicker = styled(RangePicker)`
  width: ${({ width }: IRangePickerComponentProps) => (width ? width : "auto")};
  height: ${({ height }: IRangePickerComponentProps) =>
    height ? height : "auto"};
  border-radius: ${({ borderRadius }: IRangePickerComponentProps) =>
    borderRadius ? borderRadius : "5px"};
  @media (max-width: 1347px) {
    width: 180px;
  }
`

const RangePickerComponent: React.FC<IRangePickerComponentProps> = ({
  value,
  onChange,
  picker,
  allowClear = true,
  format,
  placeholder,
  height,
  width,
  borderRadius,
  disabledDate,
}) => {
  return (
    <ConfigProvider locale={i18next.language === "ja" && jaJP}>
      <StyledRangePicker
        disabledDate={disabledDate}
        height={height}
        width={width}
        borderRadius={borderRadius}
        placeholder={placeholder}
        format={format}
        value={value}
        onChange={onChange}
        picker={picker}
        allowClear={allowClear}
      ></StyledRangePicker>
    </ConfigProvider>
  )
}

export { RangePickerComponent }
