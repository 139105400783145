import { t } from "i18next"

export const calculate_age = (dob1) => {
  const today = new Date()
  const birthDate = new Date(dob1)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }

  return `${age} ${t("years")}`
}
