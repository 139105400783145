import { Result } from "antd"

interface IErrorComponent {
  status?: "success" | "error" | "info" | "warning" | 404 | 403 | 500
  title?: number
  subTitle?: string
}
const ErrorComponent: React.FC<IErrorComponent> = ({
  status,
  title,
  subTitle,
}) => {
  return <Result status={404} title={title} subTitle={subTitle} />
}

export { ErrorComponent }
