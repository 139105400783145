import { API } from "@project/shared"
import { IMerchant } from "../../interface"

export interface IReturnMerchant {
  data?: Array<IMerchant>
  count?: number
}

export const fetchMerchent = async ({ queryKey }): Promise<any> => {
  const page = queryKey[1]
  const limit = queryKey[2]
  const keyword = queryKey[3]
  const status = queryKey[4]
  const params = {
    page: page,
    limit: limit,
    keyword: keyword,
    status: status,
  }
  if (status == "") {
    delete params.status
  }
  const res = await API.get<any>(`/facilities`, {
    params,
  })

  return res
}

export const createMerchant = async (data): Promise<any> => {
  return API.post(`/facilities`, data)
}

export const getOneMerchant = async ({ queryKey }): Promise<IMerchant> => {
  const id = queryKey[1]
  const { data } = await API.get<IMerchant>(`/facilities/${id}`)
  return data
}

export const updateMerchant = async (data, id): Promise<any> => {
  return API.put(`/facilities/${id}`, data)
}
export const updateMerchantPassword = async (
  data: { password: string },
  id: number
): Promise<any> => {
  return API.post("staffs/change_password", { password: data?.password, id })
}
