import { Button, InputField, theme } from "@project/shared"
import { Spin } from "antd"
import { FocusError } from "focus-formik-error"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { UseMutateFunction } from "react-query"
import styled from "styled-components"
import * as yup from "yup"
import { HtmlType, UserStatus } from "../../../constants"
import { IAdminManagement } from "../../../interface"
import { ErrorLabel, RadioButton } from "../../atoms"

const Container = styled.div`
  .label {
    ${theme.typography.subLabel};
  }
  .error {
    ${theme.typography.subLabel};
    color: ${theme.font.colorAlert};
  }
  .field-wrapper {
    margin-bottom: 10px;
  }
  .button-container {
    display: flex;
    justify-content: end;
  }
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`
interface AdminProps {
  id?: string
  isLoading?: boolean
  onSubmit?: UseMutateFunction<
    IAdminManagement,
    unknown,
    IAdminManagement | void
  >
  data?: IAdminManagement
}

const AdminForm: React.FC<AdminProps> = ({ isLoading, onSubmit, data, id }) => {
  const { t } = useTranslation()

  const validationSchema = yup.object().shape({
    name: yup.string().required(
      `${t("inputValidationMessage", {
        fieldName: `${t("Person incharge name")}`,
      })}`
    ),
    email: yup
      .string()
      .email(t("Email address format is incorrect"))
      .required(
        `${t("inputValidationMessage", {
          fieldName: `${t("Email address")}`,
        })}`
      ),
    password: id
      ? yup
          .string()
          .matches(
            /^(?=.*[A-Z])(?=.*\d).{6,20}$/,
            t(
              "Password must be the combination of uppercase, lowercase & numbers with at least 6 and below 20 characters"
            )
          )
      : yup
          .string()
          .matches(
            /^(?=.*[A-Z])(?=.*\d).{6,20}$/,
            t(
              "Password must be the combination of uppercase, lowercase & numbers with at least 6 and below 20 characters"
            )
          )
          .required(
            `${t("inputValidationMessage", {
              fieldName: `${t("Password")}`,
            })}`
          ),
    phone_number: yup
      .string()
      .matches(
        /^\d{2,4}-?\d{2,4}-?\d{2,4}-?\d+$/,
        t("Phone number can only contain numbers (0-9) and hyphen (-)")
      )
      .test("length", t("Phone number is incorrect"), (val) => {
        if (!val) return true
        const stripped = val.replace(/-/g, "")
        return stripped.length >= 10 && stripped.length <= 12
      })
      .required(
        `${t("inputValidationMessage", {
          fieldName: `${t("Phone")}`,
        })}`
      ),

    status: yup.string().required(
      `${t("selectValidationMessage", {
        fieldName: `${t("Usage Status")}`,
      })}`
    ),
  })

  const initialValues: IAdminManagement = data || {
    name: "",
    email: "",
    phone_number: "",
    password: "",
    status: UserStatus.USING,
    memo: "",
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => onSubmit(values),
  })
  const options = [
    { label: t("using"), value: UserStatus.USING },
    { label: t("suspended"), value: UserStatus.SUSPENDED },
  ]
  if (isLoading) {
    return (
      <LoaderWrapper>
        <Spin size={"default"} />
      </LoaderWrapper>
    )
  }
  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <FocusError formik={formik} />
        <div className={"field-wrapper"}>
          <InputField
            label={t("Person incharge name")}
            name={"personInchargeName"}
            error={formik.touched.name && formik.errors.name}
            {...formik.getFieldProps("name")}
            value={formik.values.name}
            onChange={(e: { target: { value: any } }) => {
              formik.setFieldValue("name", e.target.value)
            }}
            onBlur={formik.handleBlur}
            errorClassName={"error"}
            height={"42px"}
            required
            labelClassName={"label"}
            maxLength={30}
            showCounting
          />
        </div>
        <div className={"field-wrapper"}>
          <InputField
            label={t("Phone")}
            name={"phone_number"}
            type={"text"}
            errorClassName={"error"}
            height={"42px"}
            error={formik.touched.phone_number && formik.errors.phone_number}
            value={formik.values.phone_number}
            onChange={(e: { target: { value: string } }) => {
              const pattern = /^[0-9][0-9-]*$/
              const hyphens = (e.target.value.match(/-/g) || []).length
              const digits = e.target.value.length - hyphens <= 12
              if (
                (pattern.test(e.target.value) || e.target.value == "") &&
                hyphens <= 3 &&
                digits
              ) {
                formik.setFieldValue("phone_number", e.target.value)
              }
            }}
            onBlur={formik.handleBlur}
            labelClassName={"label"}
            required
            maxLength={15}
            showCounting
            placeholder={"XXXX-XXXX-XXXX"}
          />
        </div>
        <div className={"field-wrapper"}>
          <InputField
            label={t("email_address")}
            errorClassName={"error"}
            height={"42px"}
            error={formik.touched.email && formik.errors.email}
            {...formik.getFieldProps("email")}
            required
            labelClassName={"label"}
          />
        </div>
        {!id && (
          <div className={"field-wrapper"}>
            <InputField
              type={"password"}
              errorClassName={"error"}
              height={"42px"}
              label={t("Password")}
              {...formik.getFieldProps("password")}
              required={id ? false : true}
              labelClassName={"label"}
              maxLength={20}
              minLength={6}
            />
            <ErrorLabel>
              <span
                className={`warning ${
                  formik.errors.password &&
                  formik.touched.password &&
                  "validation-error"
                }`}
              >
                {t(
                  "Password must be the combination of uppercase, lowercase & numbers with at least 6 and below 20 characters"
                )}
              </span>
            </ErrorLabel>
          </div>
        )}
        <div className={"field-wrapper"}>
          <RadioButton
            options={options}
            error={formik.errors.status}
            label={t("Usage Status")}
            {...formik.getFieldProps("status")}
            required
          />
        </div>
        <div className={"field-wrapper"}>
          <InputField
            type={"textarea"}
            rows={3}
            label={t("Comments")}
            labelClassName={"label"}
            {...formik.getFieldProps("memo")}
            errorClassName={"error"}
            maxLength={255}
            showCounting
          />
        </div>
        <div className={"button-container"}>
          <Button
            label={t(id ? "Update" : "Register")}
            borderRadius={"10px"}
            height={"40px"}
            htmlType={HtmlType.SUBMIT}
            disabled={!formik.dirty}
          />
        </div>
      </form>
    </Container>
  )
}
export { AdminForm }
